<template>
  <div class="c-b-c">
    <div class="d-c-title">发现企业</div>
    <div class="c-b-c-content">
      <div class="c-b-c-step">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step title="描述招商需求"></el-step>
          <el-step title="设置招商条件"></el-step>
          <el-step title="条件清单"></el-step>
          <el-step title="设置权重"></el-step>
          <el-step title="结果展示"></el-step>
        </el-steps>
      </div>
      <div class="c-b-c-input">
        <template v-if="active === 1">
          <div class="c-b-c-child-title">描述招商需求</div>
          <el-form :model="feture_name_intro" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="招商需求名称" prop="title">
              <el-input placeholder="请输入招商需求名称" v-model="feture_name_intro.title"></el-input>
            </el-form-item>
            <el-form-item>
            </el-form-item>
            <el-form-item label="招商需求描述" prop="intro">
              <el-input placeholder="请输入招商需求描述" :autosize="{ minRows: 4, maxRows: 8}" type="textarea" v-model="feture_name_intro.intro"></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="active === 2">
          <div class="c-b-c-child-title">设置招商条件</div>
          <el-checkbox-group v-model="catagorySelect">
            <el-checkbox @change="bueiness_wrapCatagory(i, item.id)" :label="item.id" v-for="(item, i) in catagoryDefine" :key="item.id">{{item.name}}</el-checkbox>
            <el-button type="small" style="margin-left: 24px" @click="selfDefineWrapShow = true">自定义</el-button>
          </el-checkbox-group>
        </template>
        <template v-if="active === 3">
          <div class="c-b-c-child-title c-b-c-child-t-color">请确认所添加的内容, 若需取消, 可在此去除勾选, 若有缺失, 请返回上一级页面, 再次进行添加</div>
          <el-table :data="demands" stripe style="width: 100%;margin-top: 20px;">
            <el-table-column align="center" prop="title" label="一级条件" width="180"></el-table-column>
            <el-table-column align="center" label="二级条件">
              <template slot-scope="scope">
                <div v-if="scope.row.catagory === 'industry'">
                  <el-checkbox-group v-model="active3industry">
                    <el-checkbox :label="item.id" v-for="item in scope.row.child.name" :key="item.id">{{item.title}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-if="scope.row.catagory === 'area'">
                  <template v-if="scope.row.child.name === '全国'">
                    <div>{{scope.row.child.name}}</div>
                  </template>
                  <template v-else>
                    <el-checkbox-group v-model="active3area">
                      <el-checkbox :label="item.id" v-for="item in scope.row.child.name" :key="item.id">{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                  </template>

                </div>
                <div v-if="scope.row.catagory !== 'industry' && scope.row.catagory !== 'area'">{{scope.row.child.name}} {{scope.row.catagory === 'income' || scope.row.catagory === 'total_money' ? '万元': ''}}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="active === 4 || active === 5">
          <div class="c-b-c-child-t-color">对筛选条件进行权重设置, 便于查看每项条件在项目中的重要性</div>
          <div class="c-b-c-child-t-child-color">注: 每个指标的权重值精度为0.0%, 且权重之和必须为100%</div>
          <el-table :data="demands" stripe style="width: 100%;margin-top: 20px;">
            <el-table-column align="center" prop="title" label="一级条件" width="180"></el-table-column>
            <el-table-column align="center" label="二级条件">
              <template slot-scope="scope">
                <div v-if="scope.row.catagory !== 'industry' && scope.row.catagory !== 'area'">{{scope.row.child.name}} {{scope.row.catagory === 'income' || scope.row.catagory === 'total_money' ? '万元': ''}}</div>
                <div v-if="scope.row.catagory === 'industry'">{{scope.row.nextIndustry}}</div>
                <div v-if="scope.row.catagory === 'area'">{{scope.row.nextArea}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="权重" width="120">
              <template slot-scope="scope">
                <el-row style="display: flex;align-items: center">
                  <el-col :span="20">
                    <el-input type="number" @change="computePercentChange(scope.row.percent, scope.$index)" style="width: 80%;" size="mini" v-model="scope.row.percent"></el-input>
                  </el-col>
                  <el-col :span="3">%</el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-total">
            <div>合计: {{totalComputedPercent}}%</div>
          </div>
        </template>
      </div>
      <div class="c-b-c-bottom">
        <el-button v-if="active !== 1" @click="submit1">上一步</el-button>
        <el-button type="primary"  size="medium" @click="nextSubmit('ruleForm')">下一步</el-button>
      </div>
    </div>
    <!--自定义招商条件的弹窗————————点击行业-->
    <el-dialog
      :title="'自定义添加' + dialogTitleCategory" top="20vh"
      :close-on-click-modal="defineDialogShow"
      :visible.sync="industryWrapDialog"
      width="40%">
      <template v-if="setConditionShow[0]">
        <el-radio-group v-model="industryTypeSelected" @change="business_changeCategory">
          <el-radio :label="1">创投行业分类</el-radio>
          <el-radio :label="2">国民经济行业分类</el-radio>
        </el-radio-group>
        <el-cascader v-if="industryTypeSelected === 1"
                collapse-tags
                v-model="industryHasSelect1"
                ref="myCascader"
                :options="industryCatagory1"
                :props="industryProps1"
                :show-all-levels="false"
                style="display:block; width:50%; margin: 15px 0" ></el-cascader>
        <el-cascader v-if="industryTypeSelected === 2"
                collapse-tags
                v-model="industryHasSelect2"
                ref="myCascader"
                :options="industryCatagory2"
                :props="industryProps2"
                :show-all-levels="false"
                style="display:block; width:50%; margin: 15px 0" ></el-cascader>
      </template>
      <template v-if="setConditionShow[1]">
        <el-radio-group v-model="bueiness_areaSelect" @change="business_changeArea">
          <el-radio :label="1">全国</el-radio>
          <el-radio :label="2">其他省市</el-radio>
        </el-radio-group>
        <el-cascader
                v-if="bueiness_areaSelect !== 1"
                collapse-tags
                v-model="bueiness_areaHasSelect"
                ref="myCascader"
                :options="areaWrap"
                :props="{multiple: true, value: 'id', label: 'name'}"
                :show-all-levels="false"
                style="display:block; width:50%; margin: 15px 0" ></el-cascader>
      </template>
      <template v-if="setConditionShow[2]">
        <div>
          <el-select v-model="scaleValue" placeholder="请选择">
            <el-option
                    v-for="item in scaleList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template v-if="setConditionShow[3]">
        <div class="working-person-wrap">
          <el-input type="number" v-model="workingPersonInput" placeholder="请输入从业人数"></el-input>
          <div class="working-person">文字提示: 我们为您选取大于等于所填人数的企业</div>
        </div>
      </template>
      <template v-if="setConditionShow[4]">
        <div class="working-person-wrap">
          <el-input type="number"  placeholder="请输入营收收入" v-model="incomeInput">
            <template slot="append">万元</template>
          </el-input>
          <div class="working-person">文字提示: 我们为您选取大于等于所填收入的企业</div>
        </div>
      </template>
      <template v-if="setConditionShow[5]">
        <div class="working-person-wrap">
          <el-input type="number"  placeholder="请输入资产总额" v-model="assetsInput">
            <template slot="append">万元</template>
          </el-input>
          <div class="working-person">文字提示: 我们为您选取大于等于所填资产的企业</div>
        </div>
      </template>
      <template v-if="setConditionShow[6]">
        <div>
          <el-select v-model="rotationValue" placeholder="请选择">
            <el-option
                    v-for="item in business_stages"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template v-if="setConditionShow[7]">
        <div>
          <el-checkbox-group v-model="honorCheckList">
            <el-checkbox :label="item.id" :key="item.id" v-for="item in honorData">{{item.title}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
      <template v-if="setConditionShow[8]">
        <el-select v-model="classInput" placeholder="请选择">
          <el-option
                  v-for="item in level_ids"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelectDefine">取 消</el-button>
        <el-button type="primary" @click="temporarySave">确 定</el-button>
      </span>
    </el-dialog>



    <!--自定义招商条件的弹窗-->
    <el-dialog
            title="自定义添加"
            :close-on-click-modal="defineDialogShow"
            :visible.sync="selfDefineWrapShow"
            width="40%"
            :before-close="selfDefineHandleClose">
      <el-form :model="selfDefineContent" :rules="selfDefineRules" ref="selfDefinetruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="指标名称" prop="name">
          <el-input placeholder="请输入指标名称" v-model="selfDefineContent.name"></el-input>
        </el-form-item>
        <el-form-item label="指标定义" prop="explain">
          <el-input placeholder="请输入指标定义" :autosize="{ minRows: 2, maxRows: 8}" type="textarea" v-model="selfDefineContent.explain"></el-input>
        </el-form-item>
        <el-form-item label="特色案例">
          <el-input placeholder="请输入特色案例" :autosize="{ minRows: 6, maxRows: 15}" type="textarea" v-model="selfDefineContent.case"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selfDefineSubmit('selfDefinetruleForm')">确定</el-button>
          <el-button @click="selfDefineHandleClose(1,1)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  import { success, warning, error } from "@/utils/notification";
  export default {
    name: "createInvestmentCondition",
    data() {
      return {
        checkList: [], //
        input: '', //
        input2: '', //
        input3: '', //
        dialogTitleCategory: '',
        active3industry: [],
        active3area: [],
        workingPersonInput: '',
        incomeInput: '',
        assetsInput: '',
        honorCheckList: [],
        classInput: '',
        setConditionShow: [],
        level_ids: [],
        honorData: [],
        business_stages: [],
        scaleValue: '',
        rotationValue: '',
        scaleList: [],
        bueiness_areaHasSelect: [],
        bueiness_areaSelect: 1,
        areaWrap: [],
        industryProps1: { multiple: true, value: 'id',label:'title', checkStrictly : true},
        industryProps2: { multiple: true, value: 'id',label:'title', checkStrictly : true},
        industryHasSelect1: [],
        industryHasSelect2: [],
        industryTypeSelected: 1,
        industryWrapDialog: false,
        industryCatagory1: [],
        industryCatagory2: [],
        catagoryDefine: [], // 自定义招商的类别
        defineDialogShow: false,
        selfDefineWrapShow: false, // 自定义招商条件的弹窗控制
        // 自定义提交的内容
        selfDefineContent: {
          name: '',
          explain: '',
          case: ''
        },
        // 自定义提交的验证
        selfDefineRules: {
          name: [
            { required: true, message: '请输入指标名称', trigger: 'blur' },
            { min: 1, max: 20, message: '长度不能超过20个字符', trigger: 'blur' }
          ],
          explain: [
            { required: true, message: '请输入指标定义', trigger: 'blur' },
            { min: 1, max: 1000, message: '长度不能超过1000个字符', trigger: 'blur' }
          ]
        },
        catagorySelect: [],
        stepFlag:1,
        active: 1,
        feture_name_intro: {
          title: '',
          intro: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入招商需求名称', trigger: 'blur' },
          ],
          intro: [
            { required: true, message: '请输入招商需求描述', trigger: 'blur' }
          ],
        },
        demands: []
      }
    },
    watch: {
      // industryTypeSelected(a) {
      //   this.industryHasSelect = []
      //   console.log(a, 'woa')
      // }
    },
    beforeRouteLeave(to, from , next) {
      if (this.active < 2 || this.active === 5) {
        next()
      } else {
        let text = ''
        if (this.active === 2) {
          text = '您正在进行企业条件的选择和创建，此时退出，所设置的数据将不会保存，确认退出？'
        } else if (this.active === 3) {
          text = '您正在进行企业的清单查看，此时退出，所设置的数据将不会保存，确认退出？'
        } else if (this.active === 4) {
          text = '您正在进行企业的权重设置，此时退出，所设置的数据将不会保存，确认退出？'
        }
        this.$confirm(text, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          next()
        }).catch(() => {
        });
      }

    },
    methods: {
      cancelSelectDefine() {
        this.industryWrapDialog = false
        // this.setConditionShow.forEach((item, i) => {
        //   if (item > 0) {
        //
        //   }
        // })
      },
      computePercentChange(percent, index) { // 计算百分比
        this.$set(this.demands[index], 'percent', Math.round(percent*Math.pow(10,1))/Math.pow(10,1))
        let totalNumber = 0, INDEX = 0, firstEmptyIndex = 0
        this.demands.forEach((item, index) =>{
          totalNumber += item.percent - 0
          if (item.percent === '') {
            INDEX++
            firstEmptyIndex = index
          }
        })
        if (INDEX === 1) {
          this.$set(this.demands[firstEmptyIndex], 'percent', 100 - totalNumber)
        }
      },
      // 临时存储格式数据，
      temporarySave() {
        this.setConditionShow.forEach((item, i) =>{
          if (item > 0) {
            this.demands.forEach((item, index) =>{
              if (item.catagory === this.catagoryDefine[i].type) {
                this.demands.splice(index, 1)
              }
            })
            let obj = {
              title: this.catagoryDefine[i].name,
              catagory: this.catagoryDefine[i].type,
              percent: '',
              child: {
                id: null,
                name: ''
              }
            }
            if (i === 0) {
              obj.child.name = []
              obj.child.type = this.industryTypeSelected

              if (this.industryTypeSelected == 1) {
                if (this.industryHasSelect1.length === 0) {
                  warning('请选择行业分类...')
                  return false
                }
                obj.child.id = this.industryHasSelect1
                let arr1 = []
                let arr2 = []
                this.industryHasSelect1.forEach(item =>{
                  arr1.push(item[0])
                  arr2.push(item[1])
                })
                this.industryCatagory1.forEach(a =>{
                  if (arr1.indexOf(a.id) > -1) {
                    this.industryHasSelect1.forEach(has => {
                      if (has.length === 1 && has[0] === a.id) {
                        obj.child.name.push(a)
                        this.active3industry.push(a.id)
                      }
                    })
                    a.children && a.children.forEach(b => {
                      this.industryHasSelect1.forEach(has => {
                        if (has.length === 2 && has[1] === b.id) {
                          obj.child.name.push(b)
                          this.active3industry.push(b.id)
                        }
                      })
                      // if (arr2.indexOf(b.id) > -1) {
                      //   obj.child.name.push(b)
                      //   this.active3industry.push(b.id)
                      // }
                    })
                  }
                })
              } else if (this.industryTypeSelected == 2) {
                if (this.industryHasSelect2.length === 0) {
                  warning('请选择行业分类...')
                  return false
                }
                obj.child.id = this.industryHasSelect2
                let arr4 = []
                this.industryHasSelect2.forEach(item =>{
                  arr4.push(item[0])
                })
                this.industryCatagory2.forEach(a =>{
                  if (arr4.indexOf(a.id) > -1) {
                    this.industryHasSelect2.forEach(c => {
                      if (c.length === 1 && c[0] === a.id) {
                        obj.child.name.push(a)
                        this.active3industry.push(a.id)
                      }
                    })
                    a.children && a.children.forEach(b => {
                      this.industryHasSelect2.forEach(c => {
                        if (c.length === 2 && c[1] === b.id) {
                          obj.child.name.push(b)
                          this.active3industry.push(b.id)
                        }
                      })
                      b.children && b.children.forEach(e => {
                        this.industryHasSelect2.forEach(c => {
                          if (c.length === 3 && c[2] === e.id) {
                            obj.child.name.push(e)
                            this.active3industry.push(e.id)
                          }
                        })
                        e.children && e.children.forEach(f => {
                          this.industryHasSelect2.forEach(c => {
                            if (c.length === 4 && c[3] === f.id) {
                              obj.child.name.push(f)
                              this.active3industry.push(f.id)
                            }
                          })
                        })
                      })
                    })
                  }
                })
              }
            }
            if (i === 1) {
              obj.child.type = this.bueiness_areaSelect
              if (this.bueiness_areaSelect === 1) {
                // 选择了全国
                obj.child.id = this.bueiness_areaSelect
                obj.child.name = '全国'
              } else if (this.bueiness_areaSelect === 2) {
                if (this.bueiness_areaHasSelect.length === 0) {
                  warning('请选择地区分类...')
                  return false
                }
                obj.child.name = []
                obj.child.id = this.bueiness_areaHasSelect
                let twoArr = []
                let twoArr1 = []
                this.bueiness_areaHasSelect.forEach(b => {
                  twoArr.push(b[0])
                  twoArr1.push(b[1])
                })
                this.areaWrap.forEach(item =>{
                  if (twoArr.indexOf(item.id) > -1) {
                    item.children.forEach(c => {
                      if (twoArr1.indexOf(c.id) > -1) {
                        obj.child.name.push(c)
                        this.active3area.push(c.id)
                      }
                    })
                  }
                })
              }
            }
            if (i == 2) {
              if (!this.scaleValue) {
                warning('请选择规模...')
                return false
              }
              this.scaleList.forEach(item =>{
                if (item.id === this.scaleValue) {
                  obj.child.name = item.title
                }
              })
              obj.child.id = this.scaleValue
            }
            if (i === 3) {
              if (!this.workingPersonInput) {
                warning('请输入从业人数...')
                return false
              }
              obj.child.name = this.workingPersonInput
              obj.child.id = this.workingPersonInput
            }
            if (i === 4) {
              if (!this.incomeInput) {
                warning('请输入营业收入...')
                return false
              }
              obj.child.name = this.incomeInput
              obj.child.id = this.incomeInput
            }
            if (i === 5) {
              if (!this.assetsInput) {
                warning('请输入资产总额...')
                return false
              }
              obj.child.name = this.assetsInput
              obj.child.id = this.assetsInput
            }
            if (i === 6) {
              if (!this.rotationValue) {
                warning('请选择融资轮次...')
                return false
              }
              this.business_stages.forEach(item =>{
                if (item.id === this.rotationValue) {
                  obj.child.name = item.title
                }
              })
              obj.child.id = this.rotationValue
            }
            if (i === 7) {
              if (this.honorCheckList.length === 0) {
                warning('请选择企业荣誉...')
                return false
              }
              this.honorData.forEach((item, index) => {
                if (this.honorCheckList.indexOf(item.id) > -1) {
                  obj.child.name += item.title + (index === this.honorData.length - 1 ? ' ' : '、')
                }
              })
              obj.child.id = this.honorCheckList
            }
            if (i === 8) {
              if (!this.classInput) {
                warning('请选择所属级别...')
                return false
              }
              this.level_ids.forEach(item => {
                if (item.id === this.classInput) {
                  obj.child.name = item.name
                }
              })
              obj.child.id = this.classInput
            }
            if (i > 8) {
              obj = {
                title: this.catagoryDefine[i].name,
                catagory: this.catagoryDefine[i].type,
                percent: '',
                child: -1
              }
            }
            // console.log('jjKjdfjksdkf', item, i, obj)
            this.demands.push(obj)
            console.log(this.demands, '我的数组')
            this.industryWrapDialog = false
          }
        })
      },
      business_changeArea(n) {
        // this.bueiness_areaHasSelect = []
        n === 2 ? this.areaWrap = JSON.parse(localStorage.getItem("area")) : this.getgetOrgins()
      },
      business_changeCategory(number) {
        // this.getCategories(number === 1 ? 'starts': 'economics')
      },
      bueiness_wrapCatagory(index, id) {
        this.dialogTitleCategory = this.catagoryDefine[index].name
        if (this.catagorySelect.indexOf(id) > -1) {
          this.setConditionShow = []
          this.catagoryDefine.forEach((item, i) => {
            this.setConditionShow.push(i === index ? 1 : 0)
          })
          index < 9 && (this.industryWrapDialog = true)
          if (index > 8) {
            let obj = {
              title: this.catagoryDefine[index].name,
              catagory: this.catagoryDefine[index].type,
              percent: '',
              child: {
                name: '',
                id: -1
              }
            }
            this.demands.push(obj)
          }
        } else {
          if (index > 8) {
            this.demands.forEach((item, ind) => {
              if (item.catagory === this.catagoryDefine[index].type) {
                this.demands.splice(ind, 1)
              }
            })
          } else {
            this.demands.forEach((item, ind) => {
              if (item.catagory === this.catagoryDefine[index].type) {
                this.demands.splice(ind, 1)
              }
            })
            if (index === 0) {
              this.industryHasSelect1 = this.industryHasSelect2 = []

            } else if (index === 1) {
              this.bueiness_areaHasSelect = []
            } else if (index === 2) {
              this.scaleValue = ''
            } else if (index === 3) {
              this.workingPersonInput = ''
            } else if (index === 4) {
              this.incomeInput = ''
            } else if (index === 5) {
              this.assetsInput = ''
            } else if (index === 6) {
              this.rotationValue = ''
            } else if (index === 7) {
              this.honorCheckList = []
            } else if (index === 8) {
              this.classInput = ''
            }
          }
        }

      },
      //分类 - 创投和国民行业列表
      async getCategories1(url) {
        const res = await this.$http.get(this.URL.adminUrl[url], {});
        this.industryCatagory1 = res.data.data;
      },
      //分类 - 创投和国民行业列表
      async getCategories2(url) {
        const res = await this.$http.get(this.URL.adminUrl[url], {});
        this.industryCatagory2 = res.data.data;
      },
      // 自定义招商条件的弹窗________关闭
      selfDefineHandleClose(close, flag) {
        this.selfDefineContent = {
          name: '',
          explain: '',
          case: ''
        }
        flag === 1 && (this.selfDefineWrapShow = false)
        flag !== 1 && close()
      },
      // 自定义招商条件的弹窗提交事件
      selfDefineSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Object.assign(this.selfDefineContent, {organ_id: sessionStorage.organizationId - 0})
            this.$http.post(this.URL.undefineds, this.selfDefineContent).then(r => {
              const data = r.data.data
              this.catagoryDefine.push({name: data.name, id: data.id, type: data.type, explain: data.explain, case: data.case})
              this.selfDefineWrapShow = false
              this.selfDefineContent = {
                name: '',
                explain: '',
                case: ''
              }
            }).catch(_ =>{
              error(_.response.data.message)
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      nextSubmit(formName) {
        console.log(this.active, '空开垦', this.active === 1)
        if (this.active === 1) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              console.log('Kk')
              ++this.active
            } else {
              console.log('error submit!!')
              return false;
            }
          })
        } else {
          if (this.active === 2) {
            let arrayNew = []
            let arrayNew1 = []
            this.catagoryDefine.forEach(item => {
              if (this.catagorySelect.indexOf(item.id) > -1) {
                arrayNew.push(item.name)
              }
            })
            this.demands.forEach((dem, ind) => {
              arrayNew1.push(dem.catagory)
              if (arrayNew.indexOf(dem.title) === -1) {
                this.demands.splice(ind, 1)
              }
            })
            this.catagorySelect = []
            this.catagoryDefine.forEach(item => {
              if (arrayNew1.indexOf(item.type) > -1) {
               this.catagorySelect.push(item.id)
              }
            })

            if (this.demands.length === 0) {
              warning('请设置招商条件...')
              return false
            }

          }
          ++this.active
          if (this.active === 4) {
            let industry = false
            let area = false
            this.demands.forEach(item =>{
              if (item.catagory === 'industry') {
                industry = true
              } else if (item.catagory === 'area' && item.child.name !== '全国') {
                area = true
              }
            })
            if (this.active3industry.length === 0 && industry) {
              if (this.demands.length === 1) {
                warning('您只设置了行业招商条件，请至少选择一个行业领域...')
                --this.active
                return false
              } else {
                this.demands.forEach((item, index) => {
                  if (item.catagory === 'industry') {
                    this.demands.splice(index, 1)
                  }
                })
              }
            }
            if (this.active3area.length === 0 && area) {
              if (this.demands.length === 1) {
                warning('您只设置了地区招商条件，请至少选择一个地区...')
                --this.active
                return false
              } else {
                this.demands.forEach((item, index) => {
                  if (item.catagory === 'area') {
                    this.demands.splice(index, 1)
                  }
                })
              }
            }
            this.demands.forEach(item =>{
              if (item.catagory === 'industry') {
                item.nextIndustry = ''
                item.child.name.forEach((d, i) => {
                  if (this.active3industry.indexOf(d.id) > -1) {
                    item.nextIndustry += d.title + (i === item.child.name.length - 1 ? '' : '、')
                  }
                })

                if (this.industryTypeSelected === 1) {
                  // 创投行业
                  let itemID = ''
                  item.child.id.forEach((a, index) => {
                    if (a.length === 1) {
                      itemID = a[0]
                    }
                    if (a.length === 2) {
                      itemID = a[1]
                    }
                    if (this.active3industry.indexOf(itemID) === -1) {
                      item.child.id.splice(index, 1)
                    }
                  })
                } else if (this.industryTypeSelected === 2) {
                  // 国民行业
                  let itemID = ''
                  item.child.id.forEach((a, index) => {
                    if (a.length === 1) {
                      itemID = a[0]
                    }
                    if (a.length === 2) {
                      itemID = a[1]
                    }
                    if (a.length === 3) {
                      itemID = a[2]
                    }
                    if (a.length === 4) {
                      itemID = a[3]
                    }
                    if (this.active3industry.indexOf(itemID) === -1) {
                      item.child.id.splice(index, 1)
                    }
                  })
                }



              } else if (item.catagory === 'area') {
                item.nextArea = ''
                if (this.bueiness_areaSelect === 2) {
                  item.child.name.forEach((d, i) => {
                    if (this.active3area.indexOf(d.id) > -1) {
                      item.nextArea += d.name + (i === item.child.name.length - 1 ? '' : '、')
                    }
                  })
                  item.child.id.forEach((a, index) => {
                    if (this.active3area.indexOf(a[1]) === -1) {
                      item.child.id.splice(index, 1)
                    }
                  })
                } else {
                  item.nextArea = '全国'
                }

              }
            })
            if (this.demands.length === 1) {
              this.demands[0].percent = 100
            } else {
              this.demands.forEach(item =>{
                item.percent = (item.percent == 100) ?  '' : item.percent
              })
            }
          }
          if (this.active === 5) {
            // 创建需求
            let flag = false
            this.demands.forEach(item => {
              if (item.percent === '') {
                flag = true
              }
            })
            if (flag) {
              warning('权重值请填写完整...')
              this.active--
            } else if (this.totalComputedPercent !== 100) {
              warning('所设置的权重比例之和不是100%，需要重新设置...')
              this.active--
            } else {
              // 配置创建参数
              let demand = []
              let weight = []
              this.demands.forEach(item => {
                if (item.catagory === 'industry') {
                  demand.push({
                    category: item.catagory,
                    child: item.child.id,
                    type: this.industryTypeSelected
                  })
                } else if (item.catagory === 'area') {
                  demand.push({
                    category: item.catagory,
                    child: item.child.id,
                    type: this.bueiness_areaSelect
                  })
                } else {
                  demand.push({
                    category: item.catagory,
                    child: item.child.id ? item.child.id : item.child
                  })
                }

                weight.push({
                  category: item.catagory,
                  value: item.percent
                })
              })
              let obj = {
                organ_id: sessionStorage.organizationId - 0,
                title: this.feture_name_intro.title,
                intro: this.feture_name_intro.intro,
                demand,
                weight
              }
              if (this.$route.query.id) {
                // 编辑
                this.$http.put(this.URL.demands + '/' + this.$route.query.id, obj).then(r => {
                  console.log(r.data.data, '请求成功了')
                  this.$router.push({
                    path: '/discoverStatistics',
                    query: {
                      organ_id: r.data.data.organ_id,
                      demand_id: r.data.data.id
                    }
                  })
                }).catch(() => {
                  this.active--
                })
              } else {
                this.$http.post(this.URL.demands, obj).then(r => {
                  console.log(r.data.data, '请求成功了')
                  this.$router.push({
                    path: '/discoverStatistics',
                    query: {
                      organ_id: r.data.data.organ_id,
                      demand_id: r.data.data.id
                    }
                  })
                }).catch(() => {
                  this.active--
                })
              }

            }
          }
        }

      },
      submit1() {
        --this.active
      },
      //获取地区
      getgetArea() {
        this.$http.get(this.URL.citysTwoChildren).then(res => {
          this.areaWrap = res.data.data
          localStorage.setItem("area", JSON.stringify(res.data.data));
        });
      },
      //获取园区
      getgetOrgins() {
        this.$http.get(this.URL.adminUrl.search_organs).then(res => {
          this.areaWrap = res.data.data;
        });
      },
      //获取规模数据
      getscaleData() {
        this.$http.get(this.URL.adminUrl.policy).then(res => {
          this.scaleList = res.data.data;
        });
      },
      //融资轮次
      async getstages() {
        const res = await this.$http.get(this.URL.adminUrl.stagesList)
        this.business_stages = res.data.data;
      },
      // 企业荣誉
      getLabels() {
        this.$http.get(this.URL.adminUrl.labels).then(res => {
          this.honorData = res.data.data;
        });
      },
      // 获取所属级别
      getLevels(){
        this.$http.get(this.URL.adminUrl.space_level)
          .then(res=>{
            this.level_ids=res.data.data
          })
      },
    },
    created() {
      this.getscaleData()
      this.getstages()
      this.getLabels()
      this.getLevels()
      this.getCategories1("starts")
      this.getCategories2("economics")
      // 获取自定义招商条件
      this.$http.get(this.URL.undefineds, {params: {organ_id: sessionStorage.organizationId - 0}}).then(r =>{
        this.catagoryDefine = r.data.data

        // 编辑功能
        if (this.$route.query.id) {
          this.$http.get(this.URL.details + '/' + this.$route.query.id, {
            params: {organ_id: sessionStorage.organizationId - 0}
          }).then(r => {
            this.feture_name_intro = {
              title: r.data.data.title,
              intro: r.data.data.intro
            }
            this.catagoryDefine.forEach(() => {
              this.setConditionShow.push(0)
            })
            r.data.data.demand.forEach(d => {
              this.catagoryDefine.forEach((c, index) => {
                if (c.type === d.category) {
                  console.log('空开方案空单怀')
                  this.catagorySelect.push(c.id)
                  // this.setConditionShow.splice(index, 1, 1)
                }
              })
              if (d.category === 'industry') {
                this.industryTypeSelected = d.type
                console.log(d.type === 1, d.type, '空qq开垦')
                if (d.type === 1) {
                  console.log(d.child, 'jjjj快快')
                  this.industryHasSelect1 = d.child
                } else if (d.type === 2) {
                  this.industryHasSelect2 = d.child
                }
              }
              if (d.category === 'area') {
                this.bueiness_areaSelect = d.type
                if (d.type === 2) {
                  this.bueiness_areaHasSelect = d.child
                }
              }
              if (d.category === 'scale') {
                this.scaleValue = d.child
              }
              if (d.category === 'member_count') {
                this.workingPersonInput = d.child
              }
              if (d.category === 'income') {
                this.incomeInput = d.child
              }
              if (d.category === 'total_money') {
                this.assetsInput = d.child
              }
              if (d.category === 'stage') {
                this.rotationValue = d.child - 0
              }
              if (d.category === 'honor') {
                this.honorCheckList = d.child
              }
              if (d.category === 'grade') {
                this.classInput = d.child
              }
            })
            this.catagoryDefine.forEach((c, ind) => {
              if (this.catagorySelect.indexOf(c.id) > -1) {
                this.$set(this.setConditionShow, ind, 1)
              }
            })
            this.temporarySave()
            r.data.data.weight.forEach(d => {
              this.demands.forEach(it => {
                if (d.category === it.catagory) {
                  it.percent = d.value
                }
              })
            })
          })
        }
      })

      if (JSON.parse(localStorage.getItem("area"))) {
        this.areaWrap = JSON.parse(localStorage.getItem("area"));
      } else {
        this.getgetArea();
      }



    },
    computed: {
      totalComputedPercent() {
        let number = 0
        this.demands.forEach(item => {
          number += item.percent - 0
        })
        if (number > 100) {
          warning('您当前输入权重之和已超过100%，请更改...')
        }
        return number > 0 ? number : ''
      }
    }
  }
</script>

<style scoped lang="scss">
  .d-c-title{font-size: 24px;font-weight: bold;color: #333;}
  .c-b-c-content{
  }
  .c-b-c-step{
    width: 80%;margin: 50px auto 0;
  }
  .c-b-c-input{
    width: 66%;margin: 50px auto 10px;border: 1px solid #bbb;border-radius: 20px;min-height: 400px;padding: 20px;
  }
  .c-b-c-bottom{
    width: 66%;margin: 0 auto 30px;
  }
  .c-b-c-child-title{
    margin-bottom: 30px;
  }
  .c-b-c-child-t-color{
    margin-bottom: 8px;
    color: #409eff;
  }
  .c-b-c-child-t-child-color{
    color: #ff0000;
  }
  .working-person-wrap{
    padding: 0 10%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .working-person{
    color: #f00;
    font-size: 12px;
    margin-top: 10px;
  }
  .table-total{
    display: flex;justify-content: flex-end;
    margin-top: 14px;
    padding-right: 10px;
    color: #333
  }
</style>
<style lang="scss">
  .c-b-c{
    .el-step.is-horizontal{
      display: flex;flex-direction: column-reverse !important;
    }
    .el-step__title.is-success, .el-step__head.is-success{
      color: #409eff;
    }
    .el-step__head.is-success{
      border-color: #409eff;
      .el-step__line{
        background-color: #409eff;
      }
    }
  }

</style>
